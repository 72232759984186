import styled from "@emotion/styled";
import { Button, Typography } from "src/components";
import theme from "src/theme";
import { Heading } from "src/pages/JoinSessionUnauthorized/styles";

export const ScheduleSessionButton = styled(Button)({
  marginTop: "62px",
});

export const SessionWrapper = styled("div")({
  background: "white",
  maxWidth: "600px",
  width: "100%",
  padding: "50px 20px 90px",
  boxShadow: "0px 0px 32px 2px rgba(124, 119, 111, 0.05)",
  borderRadius: "24px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "30px 0",

  [theme.breakpoints.down("md")]: {
    padding: "45px 15px 32px",
  },
});

export const SessionTitle = styled(Heading)({
  fontSize: "36px",
  marginBottom: "20px",
  color: "#31232F",

  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
  },
});

export const SessionText = styled(Typography)({
  // fontWeight: 500,
  whiteSpace: "break-spaces",
  textAlign: "center",
  fontSize: "18px",
  marginTop: "20px",
  marginBottom: "40px",

  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
});

export const SessionSubText = styled(Typography)({
  fontWeight: 400,
  whiteSpace: "break-spaces",
  textAlign: "center",
  fontSize: "14px",
  marginTop: "25px",
  marginBottom: "-60px",

  [theme.breakpoints.down("md")]: {
    marginBottom: "0px",
  },
});

export const SessionLogo = styled("img")({
  width: "260px",
  height: "260px",
  marginBottom: "35px",

  [theme.breakpoints.down("md")]: {
    width: "160px",
    height: "160px",
  },
});

export const SupportWrapper = styled("a")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
});

export const SupportText = styled("a")({
  fontWeight: 500,
  whiteSpace: "break-spaces",
  textAlign: "center",
  fontSize: "16px",
});

export const SupportUrl = styled("a")({
  fontWeight: 600,
  whiteSpace: "break-spaces",
  textAlign: "center",
  fontSize: "16px",
  color: theme.palette.primary.main,
});
