import { isRejected, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejected(action) && action.payload) {
      const { status, data } = action.payload;

      if (status < 400 || status >= 500) {
        return next(action);
      }

      const request = action.meta?.baseQueryMeta?.request;
      const payload: any = {
        status,
        data,
        request: {
          url: request?.url,
          method: request?.method,
        },
      };

      const endpointName = action.meta?.arg?.endpointName;
      if (endpointName) {
        payload[endpointName] = true;
      }

      Sentry.captureMessage(`Request ${endpointName} failed with ${status}`, {
        extra: payload,
        level: "error",
      });
    }

    return next(action);
  };
