import InvalidLink from "src/assets/images/invalid-link.svg";

export enum ErrorStatus {
  INVALID_LINK = 1,
  CONNECTION_ISSUES = 2,
}

export const ErrorType = {
  [ErrorStatus.INVALID_LINK]: {
    img: InvalidLink,
    title: "This link seems to be broken",
    text: "Feel free to contact support for assistance with sessions.",
  },
  [ErrorStatus.CONNECTION_ISSUES]: {
    img: "",
    title: `Seems there's an issue connecting...`,
    text: "Please wait a moment and try again, or return to the app home page.",
  },
};
