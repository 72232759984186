import { styled } from "@mui/material";
import theme from "src/theme";
import { PageWrapper } from "src/pages/JoinSessionUnauthorized/styles";

export const Wrapper = styled(PageWrapper)({
  display: "block",

  [theme.breakpoints.down("sm")]: {
    padding: "16px",
    width: "100%",
  },
});
