import { styled } from "@mui/material";
import theme, { DESKTOP_MOCKUP_HEIGHT, getResponsiveValue } from "src/theme";
import Typography from "../Typography";

export const Wrapper = styled("div")({
  background: "#F8F5ED",
  height: "100vh",
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "90px 24px 24px 24px",
  textAlign: "center",
  gap: "15px",
  overflowY: "auto",
  span: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
});

export const Logo = styled("div")({
  position: "absolute",
  top: getResponsiveValue(50, DESKTOP_MOCKUP_HEIGHT),
  left: "50%",
  transform: "translateX(-50%)",
});

export const Info = styled("div")({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  fontFamily: "Noto Serif",
  top: getResponsiveValue(46, DESKTOP_MOCKUP_HEIGHT),
  right: "80px",
  [theme.breakpoints.down("md")]: {
    right: "25px",
    top: getResponsiveValue(54, DESKTOP_MOCKUP_HEIGHT),
  },
});

export const MemberPicture = styled("img")({
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  marginRight: "16px",
  [theme.breakpoints.down("md")]: {
    width: "32px",
    height: "32px",
    marginRight: 0,
  },
});

export const MemberText = styled(Typography)({
  fontWeight: 500,
  whiteSpace: "break-spaces",
  textAlign: "center",
  fontSize: "16px",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
});

export const LogoutButton = styled("div")({
  fontFamily: "Avenir Next",
  fontWeight: 500,
  color: theme.palette.primary.main,
  fontSize: "16px",
  lineHeight: "24px",
  marginLeft: "8px",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
});
