import SessionPageLayout from "src/components/SessionPageLayout";
import {
  InvalidButton,
  InvalidLogo,
  InvalidText,
  InvalidTitle,
  InvalidWrapper,
} from "./styles";
import { ErrorStatus, ErrorType } from "./utils";
import { SUPPORT_EMAIL } from "src/constants/support";

const InvalidSession = ({
  errorStatus,
  image,
}: {
  errorStatus: ErrorStatus;
  image?: string;
}) => {
  return (
    <SessionPageLayout>
      <InvalidWrapper>
        <InvalidLogo
          src={image || ErrorType[errorStatus].img}
          alt="page_illustration"
        />
        <InvalidTitle>{ErrorType[errorStatus].title}</InvalidTitle>
        <InvalidText>{ErrorType[errorStatus].text}</InvalidText>
        {errorStatus === ErrorStatus.INVALID_LINK && (
          <InvalidButton
            id="contact-support"
            color="primary-transparent"
            onClick={() => (window.location.href = SUPPORT_EMAIL)}
          >
            Contact Support
          </InvalidButton>
        )}
      </InvalidWrapper>
    </SessionPageLayout>
  );
};

export default InvalidSession;
