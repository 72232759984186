import { useEffect, useState } from "react";
import { useGetMemberQuery } from "src/api/main";
import {
  useGetExpertQueryResolver,
  useGetSessionDetailsQueryResolver,
} from "src/api/resolvers";
import illustration from "src/assets/images/zoom-session.svg";
import Loading from "src/components/Loading";
import SessionPageLayout from "src/components/SessionPageLayout";
import JoinSessionButton from "../../components/JoinSessionButton";
import {
  SessionLogo,
  SessionSubText,
  SessionText,
  SessionTitle,
  SessionWrapper,
  SupportText,
  SupportUrl,
  SupportWrapper,
} from "../../styles";
import Countdown from "./Countdown";
import {
  SESSION_START_GAP,
  formatSessionDate,
  getTimeUntilSession,
  isSessionInMoreThanAnHour,
} from "./utils";

const SessionNotStarted = () => {
  const { data: member, isFetching } = useGetMemberQuery();
  const { data: expert } = useGetExpertQueryResolver();
  const { data: session } = useGetSessionDetailsQueryResolver();
  const [joinDisabled, setJoinDisabled] = useState(true);

  const moreThanHour = isSessionInMoreThanAnHour(session?.next_session!);

  useEffect(() => {
    if (!session) return;

    const timeLeft =
      getTimeUntilSession(session.next_session) - SESSION_START_GAP;

    const timeout = setTimeout(() => {
      setJoinDisabled(false);
    }, timeLeft);

    return () => {
      clearTimeout(timeout);
    };
  }, [session]);

  if (isFetching) {
    return <Loading />;
  }

  const { sessionDate, sessionTime } = formatSessionDate(
    session?.next_session!,
  );

  return (
    <SessionPageLayout memberInfo={member}>
      <SessionWrapper>
        <SessionLogo src={illustration} alt="page_illustration" />
        <SessionTitle>
          {moreThanHour
            ? `Happy To See You're Eager`
            : joinDisabled
              ? `Almost time for your session! `
              : `Time to join your session`}
        </SessionTitle>

        <SessionText>
          {moreThanHour
            ? `You still have some time, though. Your next session is scheduled for ${sessionTime} on ${sessionDate}`
            : joinDisabled
              ? `Your video session with your expert is set to begin in`
              : `Your session with ${expert?.full_name} is about to start. Make sure you have a good internet
            connection and join.`}
        </SessionText>
        {!moreThanHour && joinDisabled && <Countdown />}
        <JoinSessionButton disabled={joinDisabled} />
        <SessionSubText>
          You'll be able to join your session 5 minutes before your session
          start time using the button above
        </SessionSubText>
      </SessionWrapper>
      <SupportWrapper>
        <SupportText>Having trouble connecting to your session?</SupportText>
        <SupportUrl>Contact support</SupportUrl>
      </SupportWrapper>
    </SessionPageLayout>
  );
};

export default SessionNotStarted;
