import { styled } from "@mui/material";
import { Button, Typography } from "src/components";
import theme from "src/theme";

export const InvalidWrapper = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  maxWidth: "600px",
});

export const InvalidLogo = styled("img")({
  width: "260px",
  height: "260px",
  marginBottom: "32px",

  [theme.breakpoints.down("md")]: {
    width: "160px",
    height: "160px",
  },
});

export const InvalidTitle = styled(Typography)({
  fontWeight: 500,
  fontSize: 36,
  whiteSpace: "break-spaces",
  textAlign: "center",
  fontFamily: "Noto Serif",
  marginBottom: "16px",

  [theme.breakpoints.down("md")]: {
    fontSize: 28,
    lineHeight: "42px",
  },
});

export const InvalidText = styled(Typography)({
  fontWeight: 500,
  fontSize: 18,
  whiteSpace: "break-spaces",
  textAlign: "center",
  marginBottom: "40px",
  [theme.breakpoints.down("md")]: {
    fontSize: 16,},
});

export const InvalidButton = styled(Button)({
  maxWidth: "450px",
  borderRadius: "1000px",
  borderColor: theme.palette.primary.main,
  borderWidth: 2,
});
